import { z } from "zod"
import { numericFilterSchema } from "~/utils/signal"
import { signalDateFilterSchema } from "~/utils/signalDate"

export type IpoDBFilters = z.infer<typeof ipoFiltersValidation>

const ipoOverviewFiltersSchema = z
  .object({
    Company: z.array(z.string()),
    wentPublicOn: signalDateFilterSchema,
    valuationPrice: numericFilterSchema(),
    sharePrice: numericFilterSchema(),
    moneyRaised: numericFilterSchema(),
    stockExchangeSymbol: z.array(z.string()),
  })
  .partial()

export const ipoFiltersValidation = ipoOverviewFiltersSchema

export const ipoAdvancedFiltersSchema = z.object({
  "IPO Info": z.object({ "IPO Info": ipoOverviewFiltersSchema }),
})

export const ipoQuickFiltersSchema = ipoOverviewFiltersSchema
export type AcquisitionQuickFilters = z.infer<typeof ipoQuickFiltersSchema>
