import { Tag, TagProps } from "@chakra-ui/react"
import { GrowthStage } from "@prisma/client"
import { pick } from "ramda"
import { ReactNode } from "react"
import { isNullish } from "~/utils/values"

export const GROWTH_STAGE_LABEL = {
  [GrowthStage.no_funding]: "Bootstrapped",
  [GrowthStage.bootstrapped]: "Bootstrapped",
  [GrowthStage.pre_launch]: "Pre-Launch",
  [GrowthStage.seed]: "Pre-seed / Seed",
  [GrowthStage.early]: "Early Stage",
  [GrowthStage.growing]: "Growth Stage",
  [GrowthStage.late]: "Late Stage",
  [GrowthStage.exit]: "Exit Stage",
} as const

declare type GrowthBadgeTheme = Partial<Record<GrowthStage, string>>

export const companyGrowthBadgeTheme: GrowthBadgeTheme = {
  [GrowthStage.no_funding]: "yellow.600",
  [GrowthStage.seed]: "orange.500",
  [GrowthStage.early]: "green.500",
  [GrowthStage.growing]: "green.700",
  [GrowthStage.late]: "green.900",
  [GrowthStage.exit]: "blue.500",
}

export const strategicGrowthBadgeTheme: GrowthBadgeTheme = {
  [GrowthStage.pre_launch]: "purple.500",
  [GrowthStage.bootstrapped]: "yellow.600",
  [GrowthStage.early]: "green.500",
  [GrowthStage.growing]: "green.700",
  [GrowthStage.late]: "green.900",
}

const companyGrowthStageKeys = Object.keys(companyGrowthBadgeTheme)
const strategicGrowthStageKeys = Object.keys(strategicGrowthBadgeTheme)

export const CompanyGrowthStageEnum = pick(companyGrowthStageKeys, GrowthStage)
export const StratintelGrowthStageEnum = pick(
  strategicGrowthStageKeys,
  GrowthStage
)

const GrowthBadge = ({
  growthStage,
  theme,
}: {
  growthStage: GrowthStage
  theme: GrowthBadgeTheme
}) => {
  const color = theme[growthStage]

  return (
    <ColoredTag color={color}>{GROWTH_STAGE_LABEL[growthStage]}</ColoredTag>
  )
}

export const StrategicGrowthBadge = ({
  growthStage,
}: {
  growthStage: GrowthStage
}) => {
  return (
    <GrowthBadge growthStage={growthStage} theme={strategicGrowthBadgeTheme} />
  )
}

export const CompanyGrowthBadge = ({
  growthStage,
}: {
  growthStage: GrowthStage | null
}) => {
  if (isNullish(growthStage)) {
    return null
  }

  return (
    <GrowthBadge growthStage={growthStage} theme={companyGrowthBadgeTheme} />
  )
}

const sizeProps = (size: string) =>
  ({
    xs: { fontSize: "8px", outlineOffset: "none", px: 1, py: 0.5, minH: 0 },
    sm: { fontSize: "xx-small", outlineOffset: "none" },
    md: { fontSize: "xs", outlineOffset: "2px" },
    lg: { fontSize: "sm", outlineOffset: "4px" },
  }[size])

export const ColoredTag = ({
  color,
  children,
  size = "md",
  ...props
}: {
  color?: string
  children: ReactNode
  size?: "xs" | "sm" | "md" | "lg"
} & TagProps) => {
  const extraSizeProps = sizeProps(size)

  return (
    <Tag
      borderRadius="full"
      variant="outline"
      color={color}
      border="1px solid"
      borderColor={color}
      boxShadow="none"
      fontWeight="medium"
      overflow="hidden"
      whiteSpace="nowrap"
      py={1}
      {...extraSizeProps}
      {...props}
    >
      {children}
    </Tag>
  )
}
