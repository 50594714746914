import { Values } from "~/utils/values"

export const TALENT_SIGNAL_STATUS = {
  STEALTH: "Stealth",
  OUT_OF_STEALTH: "Out of Stealth",
  LIVE: "Live",
  HR: "HR",
} as const

export type TalentSignalStatus = Values<typeof TALENT_SIGNAL_STATUS>

export const allTalentSignalStatus =
  Object.values<TalentSignalStatus>(TALENT_SIGNAL_STATUS)

export const TALENT_SIGNAL_SENIORITY = {
  Executive: "Executive",
  Director: "Director",
  MidLevel: "Mid-Level",
  Entry: "Entry",
} as const

export type TalentSignalSeniority = Values<typeof TALENT_SIGNAL_SENIORITY>

export const allTalentSignalSeniority = Object.values<TalentSignalSeniority>(
  TALENT_SIGNAL_SENIORITY
)

export const TALENT_SIGNAL_TYPE = {
  NEW_ROLE: "New Role",
  NEW_COMPANY: "New Company",
  NEW_FUND: "New Fund",
  SEARCHING: "Searching",
  PROMOTION: "Promotion",
  INVESTMENT: "Investment",
} as const

export type TalentSignalType = Values<typeof TALENT_SIGNAL_TYPE>

export const allTalentSignalType =
  Object.values<TalentSignalType>(TALENT_SIGNAL_TYPE)

export const TALENT_SIGNAL_YEARS_OF_EXPERIENCE = {
  "0_5_YEARS": "0 to 5 years",
  "6_10_YEARS": "6 to 10 years",
  "11_20_YEARS": "11 to 20 years",
  MORE_THAN_20: "more than 20 years",
} as const

export type TalentSignalYearsOfExperience = Values<
  typeof TALENT_SIGNAL_YEARS_OF_EXPERIENCE
>

export const allTalentSignalYearsOfExperience =
  Object.values<TalentSignalYearsOfExperience>(
    TALENT_SIGNAL_YEARS_OF_EXPERIENCE
  )

export const TALENT_SIGNAL_DEPARTMENT = {
  FINANCE: "Finance",
  DB_MARKETING: "BD & Marketing",
  OPERATIONS: "Operations",
  HUMAN_RESOURCES: "Human Resources",
  PRODUCT_RESEARCH: "Product & Research",
  ENGINEERING: "Engineering",
} as const

export type TalentSignalDepartment = Values<typeof TALENT_SIGNAL_DEPARTMENT>

export const allTalentSignalDepartment = Object.values<TalentSignalDepartment>(
  TALENT_SIGNAL_DEPARTMENT
)
