import { z } from "zod"
import { FUNDING_TYPE_OPTIONS } from "~/consts/signals"
import { numericFilterSchema, setFilterSchema } from "~/utils/signal"
import { signalDateFilterSchema } from "~/utils/signalDate"

export type FundingRoundsDBFilters = z.infer<
  typeof fundingRoundsFiltersValidation
>

const fundingRoundsOverviewFiltersSchema = z
  .object({
    Company: z.array(z.string()),
    Investors: setFilterSchema(),
  })
  .partial()

export const fundingRoundsDealInfoFiltersSchema = z
  .object({
    announcedOn: signalDateFilterSchema,
    raisedAmount: numericFilterSchema(),
    fundingType: z.array(z.enum(FUNDING_TYPE_OPTIONS)),
    postMoneyValuation: numericFilterSchema(),
  })
  .partial()

export const fundingRoundsFiltersValidation =
  fundingRoundsOverviewFiltersSchema.merge(fundingRoundsDealInfoFiltersSchema)

export const fundingRoundsAdvancedFiltersSchema = z.object({
  Overview: z.object({ Overview: fundingRoundsOverviewFiltersSchema }),
  "Deal Info": z.object({
    "Deal Info": fundingRoundsDealInfoFiltersSchema,
  }),
})

export const fundingRoundsQuickFiltersSchema =
  fundingRoundsOverviewFiltersSchema

export type FundingRoundsQuickFilters = z.infer<
  typeof fundingRoundsQuickFiltersSchema
>
