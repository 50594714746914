import { noop } from "./noop"

export const JSONSafeParse = <T extends any>(
  json: string | null,
  coalesce = {} as T
): T => {
  if (json !== null) {
    try {
      return JSON.parse(json)
    } catch (e) {
      noop()
    }
  }

  return coalesce
}
