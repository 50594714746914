import { AcquisitionType } from "@prisma/client"
import { z } from "zod"
import { dateFilterSchema, numericFilterSchema } from "~/utils/signal"

export type AcquisitionDBFilters = z.infer<typeof acquisitionFiltersValidation>

const acquisitionDealInfoFiltersSchema = z
  .object({
    acquiredOn: dateFilterSchema,
    acquisitionType: z.array(z.nativeEnum(AcquisitionType)),
    announcedOn: dateFilterSchema,
    acquisitionPrice: numericFilterSchema(),
  })
  .partial()

const acquisitionAcquireeInfoFiltersSchema = z
  .object({
    acquired: z.array(z.string()),
  })
  .partial()

const acquisitionAcquirerInfoFiltersSchema = z
  .object({
    acquirer: z.array(z.string()),
  })
  .partial()

export const acquisitionFiltersValidation = acquisitionDealInfoFiltersSchema
  .merge(acquisitionAcquireeInfoFiltersSchema)
  .merge(acquisitionAcquirerInfoFiltersSchema)

export const acquisitionAdvancedFiltersSchema = z.object({
  "Deal Info": z.object({
    "Deal Info": acquisitionDealInfoFiltersSchema,
  }),
  "Acquiree Info": z.object({
    "Acquiree Info": acquisitionAcquireeInfoFiltersSchema,
  }),
  "Acquirer Info": z.object({
    "Acquirer Info": acquisitionAcquirerInfoFiltersSchema,
  }),
})
